import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, tap } from 'rxjs/operators';


@Injectable()
export class ProductSearchHttpInterceptor implements HttpInterceptor {

  private static eventCounterBehaviorSubject = new BehaviorSubject<number>(0);
  private static counter: number = 0;
  private static pendingStateSet = new Set<string>();

  // changed it to getter, so we can spyOnProperty
  static get loading$() {
    return ProductSearchHttpInterceptor.eventCounterBehaviorSubject.pipe(
      map(n => ProductSearchHttpInterceptor.pendingStateSet.size > 0),
      distinctUntilChanged()
    );
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // create unique string
    const state = 'state_' + (ProductSearchHttpInterceptor.counter++);
    const wantedRequest = !!req?.url?.includes('/products/search');

    if (wantedRequest) {
      ProductSearchHttpInterceptor.pendingStateSet.add(state);
      ProductSearchHttpInterceptor.eventCounterBehaviorSubject.next(ProductSearchHttpInterceptor.eventCounterBehaviorSubject.value + 1);
    }

    return next.handle(req).pipe(
      // to catch failed responses - note: will also go into finalize()
      catchError((err: HttpErrorResponse, caught) => {
        if (wantedRequest) {
          ProductSearchHttpInterceptor.pendingStateSet.delete(state);
          ProductSearchHttpInterceptor.eventCounterBehaviorSubject.next(ProductSearchHttpInterceptor.eventCounterBehaviorSubject.value + 1);
        }
        return throwError(err);
      }),
      // to catch successful responses
      tap((event: HttpEvent<any>) => {
        if (event?.type === HttpEventType.Response && wantedRequest) {
          ProductSearchHttpInterceptor.pendingStateSet.delete(state);
          ProductSearchHttpInterceptor.eventCounterBehaviorSubject.next(ProductSearchHttpInterceptor.eventCounterBehaviorSubject.value + 1);
        }
      }),
      // to catch cancelled responses
      finalize(() => {
        const wasDeleted = ProductSearchHttpInterceptor.pendingStateSet.delete(state);
        if (wasDeleted) {
          ProductSearchHttpInterceptor.eventCounterBehaviorSubject.next(ProductSearchHttpInterceptor.eventCounterBehaviorSubject.value + 1);
        }
      })
    );
  }

}

